import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AuthenticationRoutes from '@/routes/authentication.route';
import BrokerRoutes from '@/routes/broker.route';
import BorrowerRoutes from '@/routes/borrower.route';
import SuperAdminRoutes from '@/routes/super-admin.route';
import ProcessorRoutes from '@/routes/processor.route';
import RealtorRoutes from '@/routes/realtor.route';
import CommonRoutes from '@/routes/common.route';
import DeveloperRoutes from '@/routes/developer.route';

import AgentRoutes from '@/routes/agent.route';
// import stores from '@/store/store';
// const store: any = stores;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: '/',
  //   name: 'SignUp',
  //   component: () => import(/* webpackChunkName: "sign-up" */ '@/components/authentication/sign-up/SignUp.vue')
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import(/* webpackChunkName: "login" */ '@/components/authentication/login/Login.vue')
  // },
  ...AuthenticationRoutes,
  ...BrokerRoutes,
  ...BorrowerRoutes,
  ...ProcessorRoutes,
  ...RealtorRoutes,
  ...SuperAdminRoutes,
  ...CommonRoutes,

  ...AgentRoutes,
  ...DeveloperRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

// router.beforeEach((to, from, next) => {


//   if(to.matched.some(record => record.meta.requiresAuth)) {
//       if (store.state.sessionObj.token == null) {
//           next({
//               path: '/login',
//               params: { nextUrl: to.fullPath }
//           })
//       } else {
//         next()
//       }
//   } else {
//       next()
//   }
// })

export default router

