/* eslint-disable */
import url from './environments/index';
import Vue from 'vue';
import store from '@/store/store'


/**
 * Backend server's base api url based on environment.
 */
export const BASE_API_URL = url.SERVER_BASE_URL;
/**
 * AWS base url based on environment
 */
export const AWS_S3_BASE_URL = url.AWS_S3_BASE_URL;

/**
 * Phone Extension according to environment
 */
export const PHONE_EXT = url.PHONE_EXT;
/**
 * Environment name
 */
export const ENV = url.ENV;
/**
 * CLIENT URL
 */
export const CLIENT_URL = url.CLIENT_URL;

export async function setDirectAccessibleData() {
    let stateData: any = store.state;
    if (stateData.sessionObj.token) {
        Vue.prototype.$userId = stateData.sessionObj.userId;
        Vue.prototype.$userType = stateData.sessionObj.userType;
    }
}





export const EventBus = new Vue();
/* eslint-disable */