export default {
    setSessionData({ commit }: any, loginResp: any) {
        commit('SET_LOGIN_SESSION_DATA', loginResp);
    },
    clearSessionData({ commit }: any) {
        commit('CLEAR_SESSION_DATA')
    },
    setPathInSession({ commit }: any, data: any) {
        commit('SET_PATH_IN_SESSION', data);
    },
}