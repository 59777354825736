import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;
import {routeto} from './common.route';

export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 1) next();
        else  next({ name: await routeto(to)})
        
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'SuperAdminDashboard' })
    }
};

export default [
    {
        path: '/superadmin-dashboard',
        name: "SuperAdminDashboard",
        component: () => import(/* webpackChunkName: "SuperAdminDashboard" */'@/components/super-admin/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "SuperAdminDashboard"
        }
    },
    {
        path: '/manageProcessor',
        name: "ManageProcessor",
        component: () => import(/* webpackChunkName: "ManageProcessor" */'@/components/super-admin/manage-processor/ManageProcessor.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageProcessor"
        }
    },
    {
        path: '/roles',
        name: "Roles",
        component: () => import(/* webpackChunkName: "Roles" */'@/components/super-admin/manage-role/ManageRole.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Roles"
        }
    },
    {
        path: '/appraisal',
        name: "Appraisal",
        component: () => import(/* webpackChunkName: "Appraisal" */'@/components/super-admin/appraisal/Appraisal.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Appraisal"
        }
    },
    {
        path: '/company-details',
        name: "CompanyDetails",
        component: () => import(/* webpackChunkName: "CompanyDetails" */'@/components/super-admin/company-details/CompanyDetails.vue'),
        meta: {
            requiresAuth: true,
            title: "CompanyDetails"
        }
    },
    {
        path: '/agent',
        name: "Agent",
        component: () => import(/* webpackChunkName: "agent" */'@/components/super-admin/agent/Agent.vue'),
        meta: {
            requiresAuth: true,
            title: "Agent"
        }
    },
    {
        path: '/agent-contacts',
        name: "AgentContacts",
        component: () => import(/* webpackChunkName: "agent-contacts" */'@/components/super-admin/agent/AgentContact.vue'),
        meta: {
            requiresAuth: true,
            title: "AgentContacts"
        }
    },
    {
        path: '/settings',
        name: "Settings",
        component: () => import(/* webpackChunkName: "Settings" */'@/components/super-admin/settings/Settings.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Settings"
        }
    },
    {
        path: '/manage-loan',
        name: "ManageLoan",
        component: () => import(/* webpackChunkName: "ManageLoan" */'@/components/super-admin/manage-loan/ManageLoan.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageLoan"
        }
    },
    
    {
        path: '/manage-conditions',
        name: "Conditions",
        component: () => import(/* webpackChunkName: "Conditions" */'@/components/super-admin/manage-conditions/Conditions.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageConditions"
        }
    },
    {
        path: '/edit-conditions',
        name: "Edit-Conditions",
        component: () => import(/* webpackChunkName: "Conditions" */'@/components/super-admin/manage-conditions/EditConditions.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageConditions"
        }
    },
    {
        path: '/add-conditions',
        name: "Add-Conditions",
        component: () => import(/* webpackChunkName: "Conditions" */'@/components/super-admin/manage-conditions/EditConditions.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageConditions"
        }
    },
    {
        path: '/email-templates',
        name: "Email Templates",
        component: () => import(/* webpackChunkName: "Email Templates" */'@/components/super-admin/manage-emails/ManageEmails.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Email Templates"
        }
    },
    {
        path: '/edit-template',
        name: "Edit Template",
        component: () => import(/* webpackChunkName: "Edit Templates" */'@/components/super-admin/manage-emails/EditTemplate.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Edit Templates"
        }
    },
    {
        path: '/data-show',
        name: "Data Show",
        component: () => import(/* webpackChunkName: "Edit Templates" */'@/components/super-admin/manage-emails/DataShow.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Edit Templates"
        }
    },
    {
        path: '/add-template',
        name: "Add Template",
        component: () => import(/* webpackChunkName: "Add Templates" */'@/components/super-admin/manage-emails/EditTemplate.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Add Templates"
        }
    },
    {
        path: '/view-history',
        name: "View History",
        component: () => import(/* webpackChunkName: "View History" */'@/components/super-admin/manage-loan/ViewHistory.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "View History"
        }
    },
    {
        path: '/deleted-loans',
        name: "Deleted Loans",
        component: () => import(/* webpackChunkName: "Deleted Loans" */'@/components/super-admin/deleted-loans/DeletedLoan.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Deleted Loans"
        }
    },
  
]