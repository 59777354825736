// whenever any value is added here please make sure to add in constant.js file too

export const ATTACHMENT_TYPE = [
    { id: 1, name: 'Attached', value: 'Attached' },
    { id: 2, name: 'Detached', value: 'Detached' }
]
export const APPLICABLE_FOR_TASK = [
    { id: 1, name: 'Title Order', value: 'Title Order' },
    { id: 2, name: 'Insurance', value: 'Insurance' }
]


export const CONDITIONS_CATEGORY = [
    { id: 1, name: 'Asset', value: 'Asset' },
    { id: 2, name: 'Credit', value: 'Credit' },
    { id: 3, name: 'Income', value: 'Income' },
    { id: 4, name: 'Miscellaneous', value: 'Miscellaneous' },
    { id: 5, name: 'Property', value: 'Property' },
    { id: 6, name: 'FHA', value: 'FHA' },
    { id: 7, name: 'VA', value: 'VA' },
    { id: 8, name: 'USDA', value: 'USDA' }
]

export const USER_TYPES=[
    {id:1,name:"Super Admin"},
    {id:2,name:"Broker"},
    {id:3,name:"Processor"},
    {id:4,name:"Borrower"},
    {id:5,name:"Realtor"},
    {id:6,name:"Developer"},
]

export const MASTER_CREDENTIALS_CATEGORY = [
    { id: 1, name: 'Employment-Work Number', value: 'Employment-WorkNumber' },
    { id: 2, name: 'Condo Question Contact', value: 'CondoQuestionContact' },
    { id: 3, name: 'Flood Agent', value: 'FloodAgent' },
    { id: 4, name: 'Hazard Agent', value: 'Hazard Agent' },
]

export const DOCUMENT_CATEGORY = [
    { id: 1, name: 'Asset', value: 'Asset' },
    { id: 2, name: 'Income', value: 'Income' },
    { id: 3, name: 'Property', value: 'Property' },
    { id: 4, name: 'ThirdParty', value: 'ThirdParty' },
    { id: 5, name: 'Other', value: 'Other' }
]


export const MARITAL_STATUS = [
    { id: 1, name: 'Married', value: 'Married' },
    { id: 2, name: 'Separated', value: 'Separated' },
    { id: 3, name: 'Unmarried', value: 'Unmarried' }
]

export const LOAN_PURPOSE = [
    { id: 1, name: 'Purchase', value: 'Purchase' },
    { id: 2, name: 'Refinance', value: 'Refinance' },
    { id: 3, name: 'Other', value: 'Other' }
]

export const LOAN_TYPE = [
    { id: 1, name: 'Conventional', value: 'Conventional' },
    { id: 2, name: 'VA', value: 'VA' },
    { id: 3, name: 'FHA', value: 'FHA' },
    { id: 4, name: 'USDA Rural Development', value: 'USDARuralDevelopment' },
    { id: 5, name: 'Other', value: 'Other' }
]

export const PROPERTY_USAGE_TYPE = [
    { id: 1, name: 'Primary Residence', value: 'PrimaryResidence' },
    { id: 2, name: 'Second Home', value: 'SecondHome' },
    { id: 3, name: 'Investment', value: 'Investment' },
]

export const CITIZENSHIP = [
    { id: 1, name: 'US Citizen', value: 'USCitizen' },
    { id: 2, name: 'Permanent Resident Alien', value: 'PermanentResidentAlien' },
    { id: 3, name: 'Non-Permanent Resident Alien', value: 'NonPermanentResidentAlien' }
]

export const VA_TYPE = [
    { id: 1, name: 'First Time Use', value: 'firstTimeUse' },
    { id: 2, name: 'Subsequent Use', value: 'subsequentUse' },
    { id: 3, name: 'Exempt', value: 'exempt' }
]

export const CONTACT_TYPE = [
    { id: 1, name: 'Home', value: 'Home' },
    { id: 2, name: 'Mobile', value: 'Mobile' },
    { id: 3, name: 'Work', value: 'Work' }
]

export const BORROWER_RESIDENCE_TYPE = [
    { id: 1, name: 'Current', value: 'Current' },
    { id: 2, name: 'Prior', value: 'Prior' },
]

export const BORROWER_RESIDENCE_BASIS_TYPE = [
    { id: 1, name: 'Living Rent Free', value: 'LivingRentFree' },
    { id: 2, name: 'Own', value: 'Own' },
    { id: 3, name: 'Rent', value: 'Rent' }
]

export const MILITARY_STATUS = [
    { id: 1, name: 'Active Duty', value: 'ActiveDuty' },
    { id: 2, name: 'Reserve National Guard Never Activated', value: 'ReserveNationalGuardNeverActivated' },
    { id: 3, name: 'Veteran', value: 'Veteran' }
]

export const LIEN_PRIORITY_TYPE = [
    { id: 1, value: 'FirstLien', name: 'First Lien' },
    { id: 2, value: 'SecondLien', name: 'Second Lien' },
    { id: 3, value: 'ThirdLien', name: 'Third Lien' },
    { id: 4, value: 'FourthLien', name: 'Fourth Lien' }
]

export const AMORTIZATION_TYPE = [
    { id: 1, name: 'Adjustable Rate', value: 'AdjustableRate' },
    { id: 2, name: 'GEM', value: 'GEM' },
    { id: 3, name: 'Fixed', value: 'Fixed' },
    { id: 4, name: 'GPM', value: 'GPM' },
    { id: 5, name: 'Other', value: 'Other' }
]

export const OTHER_INCOME_SOURCE = [
    { id: 1, name: 'Accessory Unit Income', value: 'AccessoryUnitIncome' },
    { id: 2, name: 'Alimony', value: 'Alimony' },
    { id: 3, name: 'Automobile Allowance', value: 'AutomobileAllowance' },
    { id: 4, name: 'Boarder Income', value: 'BoarderIncome' },
    { id: 5, name: 'Capital Gains', value: 'CapitalGains' },
    { id: 6, name: 'Child Support', value: 'ChildSupport' },
    { id: 7, name: 'Defined Contribution Plan', value: 'DefinedContributionPlan' },
    { id: 8, name: 'Disability', value: 'Disability' },
    { id: 9, name: 'Employment Related Account', value: 'EmploymentRelatedAccount' },
    { id: 10, name: 'Foster Care', value: 'FosterCare' },
    { id: 11, name: 'Housing or Parsonage', value: 'HousingAllowance' },
    { id: 12, name: 'Housing ChoiceVoucher Program', value: 'HousingChoiceVoucherProgram' },
    { id: 13, name: 'Interest and Dividends', value: 'DividendsInterest' },
    { id: 14, name: 'Mortgage Credit Certificate', value: 'MortgageCreditCertificate' },
    { id: 15, name: 'Mortgage Differential', value: 'MortgageDifferential' },
    { id: 16, name: 'Non-Borrower Household Income', value: 'NonBorrowerHouseholdIncome' },
    { id: 17, name: 'Notes Receivable Installment', value: 'NotesReceivableInstallment' },
    { id: 18, name: 'Retirement', value: 'Pension' },
    { id: 19, name: 'Public Assistance', value: 'PublicAssistance' },
    { id: 20, name: 'Royalty Payments', value: 'Royalties' },
    { id: 21, name: 'Separate Maintenance', value: 'SeparateMaintenance' },
    { id: 22, name: 'Social Security', value: 'SocialSecurity' },
    { id: 23, name: 'Temporary Leave', value: 'TemporaryLeave' },
    { id: 24, name: 'Tip Income', value: 'TipIncome' },
    { id: 25, name: 'Trust', value: 'Trust' },
    { id: 26, name: 'Unemployment', value: 'Unemployment' },
    { id: 27, name: 'VA Benefits', value: 'VABenefitsNonEducational' },
    { id: 28, name: 'Other', value: 'Other' }
]

export const LIABILITY_ACCOUNT_TYPE = [
    { id: 1, name: 'Installment', value: 'Installment' },
    { id: 2, name: 'Lease Payment', value: 'LeasePayment' },
    { id: 3, name: 'Open 30Day Charge Account', value: 'Open30DayChargeAccount' },
    { id: 4, name: 'Revolving', value: 'Revolving' },
    // { id: 5, name: 'Mortgage Loan', value: 'MortgageLoan' },
    // { id: 6, name: 'Heloc', value: 'HELOC' },
    { id: 7, name: 'Other', value: 'Other' }
]

export const OTHER_LIABILITIES_AND_EXPENSES = [
    { id: 1, name: 'Alimony', value: 'Alimony' },
    { id: 2, name: 'Child Support', value: 'ChildSupport' },
    { id: 3, name: 'Job Related Expenses', value: 'JobRelatedExpenses' },
    { id: 4, name: 'Separate Maintenance Expense', value: 'SeparateMaintenanceExpense' },
    { id: 5, name: 'Other', value: 'Other' }
]

export const OWNERSHIP_INTEREST = [
    { id: 1, name: 'GreaterThanOrEqualTo25Percent', value: 'GreaterThanOrEqualTo25Percent' },
    { id: 2, name: 'LessThan25Percent', value: 'LessThan25Percent' }
]

export const TRUST_CLASSIFICATIONS = [
    { id: 1, name: 'Title Will be Held by an Inter Vivos (Living) Trust', value: 'LandTrust' },
    { id: 2, name: 'Title Will be Held by a Land Trust', value: 'LivingTrust' }
]

export const PROPERTY_ESTATES = [
    { id: 1, name: 'Fee Simple', value: 'FeeSimple' },
    { id: 2, name: 'Leasehold', value: 'Leasehold' }
]

export const RELATIONSHIP_VESTINGS = [
    { id: 1, name: 'Individual', value: 'Individual' },
    { id: 2, name: 'Joint Tenancy With Right Of Survivorship', value: 'JointTenantsWithRightOfSurvivorship' },
    { id: 3, name: 'Life Estate', value: 'LifeEstate' },
    { id: 4, name: 'Tenancy by The Entirety', value: 'TenantsByTheEntirety' },
    { id: 5, name: 'Tenancy In Common', value: 'TenantsInCommon' },
    { id: 6, name: 'Other', value: 'Other' }
]

export const HOUSING_EXPENSE_TIMING_TYPE = [
    { id: 1, name: 'Present', value: 'Present' },
    { id: 2, name: 'Proposed', value: 'Proposed' }
]

export const HOUSING_EXPENSES = [

]

export const NATIVE_AMERICAN_LAND_TYPE_OTHER = [
    { id: 1, name: 'Alaska Native Corporation Land', value: 'AlaskaNativeCorporationLand' },
    { id: 2, name: 'Tribal Trust Land Off Reservation', value: 'TribalTrustLandOffReservation' },
    { id: 3, name: 'Tribal Trust Land On a Reservation', value: 'TribalTrustLandOnAReservation' }
]

export const NATIVE_AMERICAN_LAND_TYPE = [
    { id: 1, name: 'Fee Simple On a Reservation', value: 'FeeSimple' },
    { id: 2, name: 'Individual Trust Land (Allotted/Restricted)', value: 'IndividualTrustLand' },
    { id: 3, name: 'Other', value: 'Other' }
]

export const GIFT_ASSET_TYPE_SOURCE = [
    { id: 1, name: 'Community Non-Profit', value: 'CommunityNonProfit' },
    { id: 2, name: 'Federal Agency', value: 'FederalAgency' },
    { id: 3, name: 'Relative', value: 'Relative' },
    { id: 4, name: 'State Agency', value: 'StateAgency' },
    { id: 5, name: 'Lender', value: 'Lender' },
    { id: 6, name: 'Employer', value: 'Employer' },
    { id: 7, name: 'Local Agency', value: 'LocalAgency' },
    { id: 8, name: 'Relegious Non-Profit', value: 'RelegiousNonProfit' },
    { id: 9, name: 'Unmarried Partner', value: 'UnmarriedPartner' },
    { id: 10, name: 'Other', value: 'Other' }

]

export const ASSET_ACCOUNT_TYPE = [
    { id: 1, name: 'Bonds', value: 'Bond' },
    { id: 2, name: 'Bridge Loan Proceeds', value: 'BridgeLoanNotDeposited' },
    { id: 3, name: 'Certificate of Deposit', value: 'CertificateOfDepositTimeDeposit' },
    { id: 4, name: 'Checking', value: 'CheckingAccount' },
    { id: 5, name: 'Individual Development', value: 'IndividualDevelopmentAccount' },
    { id: 6, name: 'Cash Value of Life Insurance', value: 'LifeInsurance' },
    { id: 7, name: 'Money Market', value: 'MoneyMarketFund' },
    { id: 8, name: 'Mutual Fund', value: 'MutualFund' },
    { id: 9, name: 'Retirement Account', value: 'RetirementFund' },
    { id: 10, name: 'Savings', value: 'SavingsAccount' },
    { id: 11, name: 'Stocks', value: 'Stock' },
    { id: 12, name: 'Stock Options', value: 'StockOptions' },
    { id: 13, name: 'Trust Account', value: 'TrustAccount' },
    { id: 14, name: 'Cash On Hand', value: 'CashOnHand' }
]

export const ASSET_TYPE = [
    { id: 1, name: 'Proceeds from Real Estate Property to be sold on or before closing', value: 'PendingNetSaleProceedsFromRealEstateAssets' },
    { id: 2, name: 'Proceeds from Sale Of Non-Real Estate Asset', value: 'ProceedsFromSaleOfNonRealEstateAsset' },
    { id: 3, name: 'Secured Borrowed Funds', value: 'ProceedsFromSecuredLoan' },
    { id: 4, name: 'Unsecured Borrowed Funds', value: 'ProceedsFromUnsecuredLoan' },
    { id: 5, name: 'Other', value: 'Other' }
]

export const ASSET_CREDIT_PURCHASE_TYPE = [
    { id: 1, name: 'Earnest Money', value: 'EarnestMoney' },
    { id: 2, name: 'Employer Assistance', value: 'EmployerAssistedHousing' },
    { id: 3, name: 'Lease Purchase Fund', value: 'LeasePurchaseFund' },
    { id: 4, name: 'Relocation Funds', value: 'RelocationFunds' },
    { id: 5, name: 'Sweat Equity', value: 'SweatEquity' },
    { id: 6, name: 'Trade Equity', value: 'TradeEquityFromPropertySwap' },
    { id: 7, name: 'Lot Equity', value: 'LotEquity' },
    { id: 8, name: 'Rent Credit', value: 'RentCredit' },
    { id: 9, name: 'Other', value: 'Other' }
]


export const GIFT_TYPE = [
    { id: 1, name: 'Cash Gift', value: 'GiftOfCash' },
    { id: 2, name: 'Gift Of Equity', value: 'GiftOfPropertyEquity' },
    { id: 3, name: 'Grant', value: 'Grant' },
]


export const LOADER_TITLE = [
    { id: 1, title: 'Loading...' },
    { id: 2, title: 'Saving...' },
    { id: 3, title: 'Updating...' },
    { id: 4, title: 'Deleting...' },
    { id: 5, title: 'Restoring...' },
    { id: 6, title: 'Uploading...' },
    { id: 7, title: 'Creating...' },
    { id: 8, title: 'Moving...' },
    { id: 9, title: 'Renaming...' },
    { id: 10, title: 'Publishing...' },
    { id: 11, title: 'Searching...' },
    { id: 12, title: 'Sending...' }
]

export const REFINANCE_TYPE = [
    { id: 1, name: 'Streamline', value: 'NoCashOut' },
    { id: 2, name: 'Cash Out', value: 'CashOut' },
    { id: 3, name: 'Rate And Term', value: 'LimitedCashOut' },
    // { id: 4, name: 'IRRRL', value: 'NoCashOut' }
]

export const LOAN_TERM = [
    { id: 1, term: 10, value: 120 },
    { id: 2, term: 15, value: 180 },
    { id: 3, term: 20, value: 240 },
    { id: 4, term: 25, value: 300 },
    { id: 5, term: 30, value: 360 },
    { id: 6, term: 35, value: 420 },
    { id: 7, term: 40, value: 480 },
]

export const UNIT_TYPE = [
    { id: 1, name: 'Apartment', value: 'Apartment' },
    { id: 2, name: 'Basement', value: 'Basement' },
    { id: 3, name: 'Building', value: 'Building' },
    { id: 4, name: 'Condo', value: 'Condo' },
    { id: 5, name: 'Department', value: 'Department' },
    { id: 6, name: 'Floor', value: 'Floor' },
    { id: 7, name: 'Front', value: 'Front' },
    { id: 8, name: 'Hangar', value: 'Hangar' },
    { id: 9, name: 'Key', value: 'Key' },
    { id: 10, name: 'Lobby', value: 'Lobby' },
    { id: 11, name: 'Lot', value: 'Lot' },
    { id: 12, name: 'Lower', value: 'Lower' },
    { id: 13, name: 'Office', value: 'Office' },
    { id: 14, name: 'Penthouse', value: 'Penthouse' },
    { id: 15, name: 'Pier', value: 'Pier' },
    { id: 16, name: 'Rear', value: 'Rear' },
    { id: 17, name: 'Room', value: 'Room' },
    { id: 18, name: 'Side', value: 'Side' },
    { id: 19, name: 'Space', value: 'Space' },
    { id: 20, name: 'Stop', value: 'Stop' },
    { id: 21, name: 'Suite', value: 'Suite' },
    { id: 22, name: 'Trailer', value: 'Trailer' },
    { id: 23, name: 'Unit', value: 'Unit' },
    { id: 24, name: 'Upper', value: 'Upper' },
]

export const UNMARRIED_STATUS_TYPE = [
    { id: 1, name: 'Single', value: 'Single' },
    { id: 2, name: 'Divorced', value: 'Divorced' },
    { id: 3, name: 'Widowed', value: 'Widowed' }
]

export const HOUSING = [
    { id: 1, name: 'No primary housing expense', value: 'noPrimaryHousingExpense' },
    { id: 2, name: 'Own', value: 'Own' },
    { id: 3, name: 'Rent', value: 'Rent' },
]


export const REO_PROPERTY_STATUS = [
    { id: 1, name: 'Pending Sale', value: 'PendingSale' },
    { id: 2, name: 'Retain', value: 'Retain' },
    { id: 3, name: 'Sold', value: 'Sold' }
]

export const REO_INTENDED_OCCUPANCY = [
    { id: 1, name: 'Investment', value: 'Investment' },
    { id: 2, name: 'Primary Residence', value: 'PrimaryResidence' },
    { id: 3, name: 'Second Home', value: 'SecondHome' },
    { id: 4, name: 'Other', value: 'Other' }
]

export const ESTATE_HELD = [
    { id: 1, name: 'Fee Simple', value: 'FeeSimple' },
    { id: 2, name: 'Lease Hold Expiration', value: 'Leasehold' },

]

export const MORTGAGE_LIEN_TYPE = [
    { id: 1, name: 'First Lien', value: 'FirstLien' },
    { id: 2, name: 'Subordinate Lien', value: 'SecondLien' },
]

export const TYPE_OF_RELATIONSHIP = [
    { id: 1, name: 'Civil Union', value: 'CivilUnion' },
    { id: 2, name: 'Domestic Partnership', value: 'DomesticPartnership' },
    { id: 3, name: 'Registered Reciprocal Beneficiary Relationship', value: 'RegisteredReciprocalBeneficiaryRelationship' },
    { id: 4, name: 'Other', value: 'Other' }
]

export const PRIOR_PROPERTY_USAGE_TYPE = [
    { id: 1, name: 'IP', value: 'Investment' },
    { id: 2, name: 'PR', value: 'PrimaryResidence' },
    { id: 3, name: 'SH', value: 'SecondHome' },
    // { id: 4, name: 'SR', value: 'FHA Secondary Residence' } showing in frontend but not in pdf
]

export const PRIOR_PROPERTY_TITLE_TYPE = [
    { id: 1, name: 'O', value: 'JointWithOtherThanSpouse' },
    { id: 2, name: 'SP', value: 'JointWithSpouse' },
    { id: 3, name: 'S', value: 'Sole' },
]



export const RefinanceProgramme = [
    { id: 1, name: 'Full Documentation', value: 'FullDocumentation' },
    { id: 2, name: 'Interest Rate Reduction Refinance Loan', value: 'InterestRateReductionRefinanceLoan' },
    { id: 3, name: 'Streamline Without Appraisal', value: 'StreamlineWithoutAppraisal' },
    { id: 4, name: 'Other', value: 'Other' },

]


export const ETHINICITY_TYPE = [
    { id: 1, name: 'Hispanic or Latino', value: 'HispanicOrLatino' },
    { id: 2, name: 'Not Hispanic or Latino', value: 'NotHispanicOrLatino' },
    { id: 3, name: 'I do not wish to provide this information', value: 'DoNotWish' },
]

export const HISPANICORLATINO_TYPE = [
    { id: 1, name: 'Mexican', value: 'Mexican' },
    { id: 2, name: 'Puerto Rican', value: 'PuertoRican' },
    { id: 3, name: 'Cuban', value: 'Cuban' },
    { id: 4, name: 'Other Hispanic or Latin Point Origin', value: 'Other' }
]

export const RACE_TYPE = [
    { id: 1, name: 'American Indian or Alaska Native', value: 'AmericanIndianOrAlaskaNative' },
    { id: 2, name: 'Asian', value: 'Asian' },
    { id: 3, name: 'Black or African American', value: 'BlackOrAfricanAmerican' },
    { id: 4, name: 'Native Hawaiian or Other Pacific Islander', value: 'NativeHawaiianOrOtherPacificIslander' },
    { id: 5, name: 'White', value: 'White' },
    { id: 6, name: 'I do not wish to provide this information', value: 'DoNotWish' }
]

export const RACE_ASIAN_TYPE = [
    { id: 1, name: 'Asian Indian', value: 'AsianIndian' },
    { id: 2, name: 'Chinese', value: 'Chinese' },
    { id: 3, name: 'Filipino', value: 'Filipino' },
    { id: 4, name: 'Japanese', value: 'Japanese' },
    { id: 5, name: 'Korean', value: 'Korean' },
    { id: 6, name: 'Vietnamese', value: 'Vietnamese' },
    { id: 7, name: 'Other Asian', value: 'OtherAsian' },
]

export const RACE_NATIVE_HAWALIAN_TYPE = [
    { id: 1, name: 'Native Hawaiian', value: 'NativeHawaiian' },
    { id: 2, name: 'Guamanian or Chamorro', value: 'GuamanianOrChamorro' },
    { id: 3, name: 'Samoan', value: 'Samoan' },
    { id: 4, name: 'Other Pacific Island', value: 'OtherPacificIslander' }
]