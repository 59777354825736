import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;

export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 7){ 
            if(localStorage.getItem("previousLink")) localStorage.removeItem("previousLink");
             next();
        } else  {
            localStorage.setItem("previousLink",window.location.pathname + window.location.search);
            next({ name: 'Login'})
        }
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'AgentDashboard' })
    }
};

export default [
    {
        path: '/agent-dashboard',
        name: "AgentDashboard",
        component: () => import(/* webpackChunkName: "AgentDashboard" */'@/components/agent/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "AgentDashboard"
        }
    },

    // {
    //     path: '/title-low',
    //     name: "TitleLow",
    //     component: () => import(/* webpackChunkName: "UploadDocuments" */'@/components/agent/upload-files/TitleLow.vue'),
    //     beforeEnter: beforeEnter,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Title Low"
    //     }
    // },

    // {
    //     path: '/title-order',
    //     name: "title order",
    //     component: () => import(/* webpackChunkName: "UploadDocuments" */'@/components/agent/upload-files/TitleOrder.vue'),
    //     beforeEnter: beforeEnter,
    //     meta: {
    //         requiresAuth: true,
    //         title: "title order"
    //     }
    // },

    {
        path: '/title-low',
        name: "low",
        component: () => import(/* webpackChunkName: "UploadDocuments" */'@/components/agent/title-reports/TitleReports.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "title"
        }
    },
    {
        path: '/title-order',
        name: "order",
        component: () => import(/* webpackChunkName: "UploadDocuments" */'@/components/agent/title-reports/TitleReports.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "title"
        }
    }
]