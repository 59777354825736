import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;
import { routeto } from './common.route';
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";


export const checkData = async (to) => {
    try {
        const res = await Axios.post(
            BASE_API_URL + "broker/dashboard/checkLoan",
            to,
            {
                headers: authHeader(),
            }
        );
        const brokerExist = res.data
        return brokerExist

    } catch (error) {
        console.log(error)
    }
};

export const beforeEnter = async (to, from, next) => {
    try {

        if (store.state.sessionObj.userType === 2 || store.state.sessionObj.userType === 1 || store.state.sessionObj.userType === 3) next()

        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'BrokerDashboard' })
    }
};

export const beforeEntering = async (to, from, next) => {
    try {

        if (store.state.sessionObj.userType === 2 || store.state.sessionObj.userType === 1 || store.state.sessionObj.userType === 3) {
            if (store.state.sessionObj.userType === 2 && to && to.query && to.query.id) {
                const data = await checkData(to)
                if (data) next()
                else next({ name: await routeto(to) })
            }
            else next();
        }
        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'BrokerDashboard' })
    }
};

export default [
    {
        path: '/broker-dashboard',
        name: "BrokerDashboard",
        component: () => import(/* webpackChunkName: "BrokerDashboard" */'@/components/broker/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Broker Dashboard"
        }
    },
    // {
    //     path: '/upload3.4',
    //     name: "Upload3.4",
    //     component: () => import(/* webpackChunkName: "Upload3.4" */'@/components/broker/upload3.4/Upload3.4.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         title: "Upload3.4"
    //     }
    // },
    {
        path: '/loan',
        name: "Loan",
        component: () => import(/* webpackChunkName: "Loan" */'@/components/broker/loan/Loan.vue'),
        beforeEnter: beforeEntering,
        meta: {
            requiresAuth: true,
            title: "Loan"
        }
    },
    {
        path: '/disclosure-sign',
        name: "DisclosureSign",
        component: () => import(/* webpackChunkName: "DisclosureSign" */'@/components/broker/loan/Loan.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Disclosure Sign"
        }
    },
    {
        path: '/loan-officer',
        name: "LoanOfficer",
        component: () => import(/* webpackChunkName: "LoanOfficer" */'@/components/broker/loan-officer/LoanOfficer.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Loan Officer"
        }
    },
    {
        path: '/vendor-credentials',
        name: "OnBoarding",
        component: () => import(/* webpackChunkName: "LoanOfficer" */'@/components/broker/on-boarding/OnBoarding.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Vendor Credentials"
        }
    },
]

// export const checkData = async (to, from, next) => {
//     try {
//          console.log(to, 'fdf')
//         // if (store.state.sessionObj.userType === 2 || store.state.sessionObj.userType === 1 || store.state.sessionObj.userType === 3) next();
//         // else  next({ name: await routeto()})
//         // Vue.nextTick(() => {
//         //     document.title = to.meta.title || 'TMPCO';
//         // });
//     } catch (error) {
//        // next({ name: 'BrokerDashboard'})
//     }
// };