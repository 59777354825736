import Vue from 'vue'
import Vuex from 'vuex'
import { VuexPersistence } from 'vuex-persist';
import Actions from './action';
import Mutations from './mutations';

Vue.use(Vuex)

const vueLocalStorage = new VuexPersistence({
  key: 'state.Tmpco',
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
      tmpcoLoader: null,
      sessionObj: {},
  },
  mutations: Mutations,
  actions: Actions,
  modules: {},
  plugins: [vueLocalStorage.plugin]
})
