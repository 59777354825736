import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;
import {routeto} from './common.route';

export const beforeEnter = async (to, from, next) => {
    try {
        if (!store.state.sessionObj.token) next();
        else  next({ name: await routeto(to)})
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: await routeto(to) })
    }
};

export default [
    {
        path: '/signup',
        name: 'SignUp',
        component: () => import(/* webpackChunkName: "SignUp" */ '../components/authentication/sign-up/SignUp.vue'),
        beforeEnter: beforeEnter,
        meta: {
            title: "SignUp"
        }
    },
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../components/authentication/login/Login.vue'),
        beforeEnter: beforeEnter,
        meta: {
            title: "Login"
        }
    },
    {
        path: '/forgotpassword',
        name: 'Forgot Password',
        component: () => import(/* webpackChunkName: "Forgot Password" */ '../components/authentication/forgot-password/ForgotPassword.vue'),
        beforeEnter: beforeEnter,
        meta: {
            title: "Forgot Password"
        }
    },

    {
        path: '/titlerequest',
        name: 'Title Request',
        component: () => import(/* webpackChunkName: "Title Request" */ '../components/authentication/TitleRequest.vue'),
        beforeEnter: beforeEnter,
        meta: {
            title: "Title Request"
        }
    },

    {
        path: '/thankyou',
        name: 'Thank You',
        component: () => import(/* webpackChunkName: "Title Request" */ '../components/authentication/ThankYou.vue'),
        beforeEnter: beforeEnter,
        meta: {
            title: "Thank You"
        }
    }




]