import stores from '@/store/store';
import { routeto } from './common.route';
import Vue from 'vue'
const store: any = stores;

export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 3) next();
        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'ProcessorDashboard' })
    }
};


export default [
    {
        path: '/processor-dashboard',
        name: "ProcessorDashboard",
        component: () => import(/* webpackChunkName: "ProcessorDashboard" */'@/components/processor/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ProcessorDashboard"
        }
    }
]