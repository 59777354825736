const SET_LOGIN_SESSION_DATA = (state: any, loginResponse: any) => {
    loginResponse.switchToCondition = state.sessionObj.switchToCondition
    loginResponse.tempLoanId = state.sessionObj.tempLoanId
    state.sessionObj = loginResponse;
    state.loaderTitleId = null
}
const SET_PATH_IN_SESSION = (state: any, data: any) => {
    state.sessionObj.switchToCondition = data.conditionDoc;
    state.sessionObj.tempLoanId = data.loanId;
    state.loaderTitleId = null
}
const CLEAR_SESSION_DATA = (state: any) => {
    state.sessionObj = {};
}

export default {
    SET_LOGIN_SESSION_DATA,
    CLEAR_SESSION_DATA,
    SET_PATH_IN_SESSION
}