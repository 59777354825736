import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;
import { routeto } from './common.route';

export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 6) next();
        else next({ name: await routeto(to) })

        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'DeveloperDashboard' })
    }
};

export default [
    {
        path: '/developer-dashboard',
        name: "DeveloperDashboard",
        component: () => import(/* webpackChunkName: "DeveloperDashboard" */'@/components/developer/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "DeveloperDashboard"
        }
    },
    {
        path: '/users',
        name: "Users",
        component: () => import(/* webpackChunkName: "DeveloperDashboard" */'@/components/developer/users/users.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Users"
        }
    },
    {
        path: '/audit-trails',
        name: "AuditTrails",
        component: () => import(/* webpackChunkName: "DeveloperDashboard" */'@/components/developer/audit-trails/auditTrails.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "AuditTrails"
        }
    },


]