import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import route from './routes'
import store from './store/store'
import '@/assets/scss/main.scss'
import VModel from 'vue-js-modal';
import Snotify from 'vue-snotify';
import VueMoment from 'vue-moment'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import VueMask from 'v-mask';
import vueNumeric from 'vue-numeric';
import { BootstrapVue } from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'

import '@/validator';

Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VModel, { dynamic: true, dynamicDefaults: { clickToClose: false } });
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('extend', extend);
// Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(VueMask);
Vue.use(VueMoment);
Vue.use(vueNumeric);
Vue.use(Snotify, {
  toast: {
    position: 'rightTop',
    showProgressBar: false
  }
})

new Vue({
  router: route,
  store,
  render: h => h(App)
}).$mount('#app')
