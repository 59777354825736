/* eslint-disable @typescript-eslint/no-explicit-any */

export default class Address {
  constructor() {
    this.street = null;
    this.unit = null;
    this.city = null;
    this.zip = null;
    this.state = null;
    this.country = 'US';
    this.unitType = null;
    this.numberOfUnits = 0;
  }

  public street: any;

  public unit: any;

  public city: any;

  public zip: any;

  public state: any;

  public country: any;

  public unitType: any;

  public numberOfUnits: number;
}
