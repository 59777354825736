import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;

export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 5) next();
        else  next({ name: 'Login'})
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'RealtorDashboard' })
    }
};

export default [
    {
        path: '/realtor-dashboard',
        name: "RealtorDashboard",
        component: () => import(/* webpackChunkName: "RealtorDashboard" */'@/components/realtor/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "RealtorDashboard"
        }
    }
]