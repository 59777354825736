







































import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus } from "../config";
import { SUPERADMIN_SIDE_NAV_LINKS } from "../constants/navigationLink.constant";

@Component
export default class SideNav extends Vue {
  public navLinks = SUPERADMIN_SIDE_NAV_LINKS;
  @Prop()
  public isHide: any;

  public showHide(){
    if(screen.width < 992)
      EventBus.$emit('show-hide',true);
  }
  // mounted() {}
}
