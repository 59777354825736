import stores from '@/store/store';
import Vue from 'vue'
import { routeto } from './common.route';

const store: any = stores;
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";



export const checkData = async (to) => {
    try {
        const res = await Axios.post(
            BASE_API_URL + "broker/dashboard/checkLoan",
            to,
            {
                headers: authHeader(),
            }
        );
        const brokerExist = res.data
        return brokerExist

    } catch (error) {
        console.log(error)
    }
};


export const beforeEnter = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 4) next();
        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: 'BorrowerDashboard' })
    }
};



export const beforeEntering = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 4 && to && to.query && to.query.id) {
            const data = await checkData(to)
            if (data) next()
            else next({ name: await routeto(to) })
        }
       

    } catch (error) {
        next({ name: await routeto(to) })
    }
};
export default [
    {
        path: '/borrower-dashboard',
        name: "BorrowerDashboard",
        component: () => import(/* webpackChunkName: "BorrowerDashboard" */'@/components/borrower/BorrowerDashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "BorrowerDashboard"
        }
       
    },
    {
        path: '/borrower-loanDetails',
        name: "BorrowerLoanDetails",
        component: () => import(/* webpackChunkName: "Loan" */'@/components/borrower/dashboard/DashBoard.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "borrower-loanDetails"
        }
    },

]