/* eslint-disable */
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
//import { messages } from 'vee-validate/dist/locale/';

let messages = {
  "alpha": "The {_field_} field may only contain alphabetic characters",
  "alpha_num": "The {_field_} field may only contain alpha-numeric characters",
  "alpha_dash": "The {_field_} field may contain alpha-numeric characters as well as dashes and underscores",
  "alpha_spaces": "The {_field_} field may only contain alphabetic characters as well as spaces",
  "between": "The {_field_} field must be between {min} and {max}",
  "confirmed": "The {_field_} field confirmation does not match",
  "digits": "The {_field_} field must be numeric and exactly contain {length} digits",
  "dimensions": "The {_field_} field must be {width} pixels by {height} pixels",
  "email": "The {_field_} field must be a valid email",
  "fax": "The {_field_} field must be a valid email",
  "excluded": "The {_field_} field is not a valid value",
  "ext": "The {_field_} field is not a valid file",
  "image": "The {_field_} field must be an image",
  "integer": "The {_field_} field must be an integer",
  "length": "The {_field_} field must be 10 digits",
  "max_value": "The {_field_} field must be {max} or less",
  "max": "The {_field_} field may not be greater than {length} characters",
  "mimes": "The {_field_} field must have a valid file type",
  "min_value": "The {_field_} field must be {min} or more",
  "min": "The {_field_} field must be at least {length} characters",
  "numeric": "The {_field_} field may only contain numeric characters",
  "oneOf": "The {_field_} field is not a valid value",
  "regex": "The {_field_} field format is invalid",
  "required_if": "The {_field_} field is required",
  "required": "The {_field_} field is required",
  "size": "The {_field_} field size must be less than {size}KB",
}


Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

extend('positive', value => {
  return value >= 0;
});

extend('length_nmls', value => {
  return value.length == 7 ? true : 'The NMLS field must be 7 digits'
});


extend('name', value => {
  return value.length > 3 && value.length < 20 ? true : 'Name field should be greater than 3';
});

extend('year', value => {
  return value.length > 4 && value.length == 4 ? true : 'Name field should be greater than 3';
});


extend('alpha_brackets', {
  message: field => 'The ' + field + ' field may only contain alphabetic characters as well as brackets .',
  validate: value => {
    let reg = /\((.*)\)/
    let regex = /^[A-Za-z\-\/ ']+$/

    if (reg.test(value)) return true;
    if (!regex.test(value)) return true;

    return true;
  }
})


extend('alpha_num_column', {
  message: field => 'The ' + field + ' field may only contain alphabetics numeric and column .',
  validate: value => {
    let regex = /^[A-Za-z\-\/ ']+$/
    if (!regex.test(value)) return true;

    return true;
  }
})





extend('alpha_num_special', {
  message: field => 'The ' + field + ' field can contain only valid characters .',
  validate: value => {
    let regex = /^[A-Za-z ][A-Za-z-!&/'^ ]*$/;
    let reg = /([\w]){1}/
    if (!regex.test(value) || !reg.test(value)) return false;
    return true;
  }})









extend('url_format', {
  message: field =>  'Please  add valid url for '+ field +' .',
  validate: value => {
    let reg = /^([A-z-A-z0-9]+)\.([A-z]{2,})/
    let regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    let regexs = /(http|https)/

    if (!reg.test(value) && !regex.test(value) && !regexs.test(value)) return false;
    return true;
  }
})

extend("decimal", {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    let decimals: any = '*';
    let separator: any = '.'
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart: any = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex: any = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'The {_field_} field must contain only numeric values'
})

/* eslint-disable */