
















































































































































































import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "../config";
import Axios from "axios";
import { authHeader } from "../services/auth";
// import ViewDocument from './ViewDocument.vue'
import MyAccount from "./my-account/MyAccount.vue";

@Component({
  components: {
    // ViewDocument,
    MyAccount
  }
})
export default class Header extends Vue {
  public imagePath = null;
  public allCommunicationAndNotification: any = [];
  public notificationAndCommunicationCount = 0;
  public isHide = false;
  public userName: any = null;
  public callNotification = false;
  public isOnBoardingCompleted = false;

  // public async exportXmlFile() {
  //   try {
  //     const response = await Axios.post(
  //       BASE_API_URL + "broker/exportXmlFile",
  //       { loanId: "d7ee6a54" },
  //       {
  //         headers: authHeader()
  //       }
  //     );
  //     if (response.data.xmlData) {
  //       const dom = document.createElement("a");
  //       const filename = "sitemap.xml";
  //       const blob = new Blob([response.data.xmlData], { type: "text/plain" });
  //       dom.setAttribute("href", window.URL.createObjectURL(blob));
  //       dom.setAttribute("download", filename);
  //       dom.dataset.downloadurl = ["text/plain", dom.download, dom.href].join(
  //         ":"
  //       );
  //       dom.draggable = true;
  //       dom.classList.add("dragout");
  //       dom.click();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async logOut() {
    try {
      const response = await Axios.post(
        BASE_API_URL + "common/pushInUserAuditTrail",
        {...this.$store.state.sessionObj},
        { headers: authHeader() }
      );
      await this.$store.dispatch("clearSessionData");
      await this.$router.push({ name: "Login" });
    } catch (error) {
      console.log(error);
      await this.$store.dispatch("clearSessionData");
      await this.$router.push({ name: "Login" });
    }
  }

  public get userType() {
    return this.$store.state.sessionObj.userType;
  }

  public async getProfilePicture() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getProfilePictureForHeader",
        { headers: authHeader() }
      );
      if (response.status === 201) this.imagePath = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  // public async getCounterOfNotificationAndComm() {
  //   try {
  //     const response = await Axios.get(
  //       `${BASE_API_URL}communication/getCounterOfNotificationAndComm`,
  //       { headers: authHeader() }
  //     );
  //     if (response.status === 201)
  //       this.notificationAndCommunicationCount = response.data.count;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async getUserName() {
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "common/getUserName/" +
          this.$store.state.sessionObj.loanId,
        { headers: authHeader() }
      );
      if (response.data) {
        if (this.$store.state.sessionObj.userType == 2 || this.$store.state.sessionObj.userType == 3|| this.$store.state.sessionObj.userType == 4 || this.$store.state.sessionObj.userType == 7) {
          this.userName =
            (response.data.userName && response.data.userName.firstName
              ? response.data.userName.firstName
              : "") +
            " " +
           
            (response.data.userName && response.data.userName.lastName
              ? response.data.userName.lastName
              : "");
        } else if(this.$store.state.sessionObj.userType == 6){
              this.userName =
            (response.data.agentName
              ? response.data.agentName &&
                response.data.agentName.firstName
              : "") +
            " " +
            (response.data.agentName &&
            response.data.agentName.middleName
              ? response.data.agentName.middleName
              : "") +
            " " +
            (response.data.agentName && response.data.agentName.lastName
              ? response.data.agentName.lastName
              : "");
              
              this.userName = "Developer"
        } 
          
        else this.userName = "Admin";
      }
    } catch (error) {
      console.log(error);
    }
  }

  public firstLetterOfUserName() {
    return this.userName ? this.userName.charAt(0).toUpperCase() : "";
  }

  public showNotifications() {
    this.callNotification = true;
    this.$router.push({
      path: "/notification",
      query: { id: this.$route.query.id }
    });
  }

  public showHide() {
    this.isHide = !this.isHide;
    EventBus.$emit("show-hide", this.isHide);
  }

  public hideSideNav() {
    this.isHide = true;
    EventBus.$emit("show-hide", this.isHide);
  }

  public async checkOnBoardingCompleted() {
    try {
      const response = await Axios.get(
        BASE_API_URL +
          "common/checkOnBoardingCompleted/",
        { headers: authHeader() }
      );
      if (response.status == 201)
      this.isOnBoardingCompleted = response.data
      
    } catch (error) {
      console.log(error)
    }
  }

  async mounted() {
    this.getProfilePicture();
    this.getUserName();
   // this.getCounterOfNotificationAndComm();
    // EventBus.$on("refreshNotificationCounter", async (data) => {
    //   this.notificationAndCommunicationCount > 0
    //     ? this.notificationAndCommunicationCount--
    //     : "";
    // });
    EventBus.$on("get-profile-pic", async () => await this.getProfilePicture());
    EventBus.$on("show-hide", (data) => {
      this.isHide = data;
    });

    if (this.userType == 2) {
      await this.checkOnBoardingCompleted();
      EventBus.$on(
        "refreshHeaderAfterOnBoarding",
        async () => await this.checkOnBoardingCompleted()
      );
    }
  }
}
