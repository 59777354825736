// SUPERADMIN SIDE NAV LINKS
export const SUPERADMIN_SIDE_NAV_LINKS = [
  {
    name: 'Dashboard',
    route: '/superadmin-dashboard',
    icon: '',
  },
  {
    name: 'Agent',
    route: '/agent',
    icon: '',
  },
  {
    name: 'Appraisal',
    route: '/appraisal',
    icon: '',
  },
  {
    name: 'Broker',
    route: '/manage-broker',
    icon: '',
  },
  {
    name: 'Company',
    route: '/company-details',
    icon: '',
  },
  {
    name: 'Condition',
    route: '/manage-conditions',
    icon: '',
  },
  {
    name: 'Lender',
    route: '/lender',
    icon: '',
  },
  {
    name: 'Loans',
    route: '/manage-loan',
    icon: '',
  },
  
  {
    name: 'Master Credentials',
    route: '/master-credentials',
    icon: '',
  },
  {
    name: 'Processor',
    route: '/manageProcessor',
    icon: '',
  },
  {
    name: 'Roles',
    route: '/roles',
    icon: '',
  },
  {
    name: 'Settings',
    route: '/settings',
    icon: '',
  },
  {
    name: 'Templates',
    route: '/email-templates',
    icon: '',
  },
  // {
  //   name: 'Deleted Loans',
  //   route: '/deleted-loans',
  //   icon: '',
  // },
  // {
  //   name: 'New Lender',
  //   route: '/newlender',
  //   icon: '',
  // },
  
  
  
 
];

// PROCESSOR SIDE NAV LINKS
export const PROCESSOR_SIDE_NAV_LINKS = [
  {
    name: 'Dashboard',
    route: '/processor-dashboard',
    icon: '',
  },
  {
    name: 'My Account',
    route: '/myAccount',
    icon: '',
  },
];
