import stores from '@/store/store';
import Vue from 'vue'
const store: any = stores;
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";

export const checkData = async (to) => {
    try {
        const res = await Axios.post(
            BASE_API_URL + "broker/dashboard/checkLoan",
            to,
            {
                headers: authHeader(),
            }
        );
        const brokerExist = res.data
        return brokerExist

    } catch (error) {
        console.log(error)
    }
};

export const routeto = async (to) => {
    try {
        if (store.state.sessionObj.userType === 1) return 'SuperAdminDashboard';
        if (store.state.sessionObj.userType === 2) return 'BrokerDashboard';
        if (store.state.sessionObj.userType === 3) return 'ProcessorDashboard';
        if (store.state.sessionObj.userType === 4) return 'BorrowerDashboard';
        if (!store.state.sessionObj.userType) {
            if (to.name == 'Mortgage Loan Conditions')
                store.dispatch("setPathInSession", { conditionDoc: true, loanId: to.query.id });
            return 'Login'
        }
    } catch (error) {
        console.log(error);
    }
}

export const beforeEnter = async (to, from, next) => {
    try {

        if (store.state.sessionObj.userType === 2 || store.state.sessionObj.userType === 3 || store.state.sessionObj.userType === 4 || store.state.sessionObj.userType === 1) {
            next();
        }

        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: await routeto(to) })
    }
};

export const beforeEnterForConditionTab = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 2)  {
            next();

        }else if (store.state.sessionObj.userType === 4) {
            console.log('store.state.sessionObj',to)
                next({
                    path: "/borrower-loanDetails",
                    query: to.query,
                  })       
            //return 'BorrowerLoanDetails';
        }
      
        

        else next({ name: await routeto(to) })
        Vue.nextTick(() => {
            document.title = to.meta.title || 'TMPCO';
        });
    } catch (error) {
        next({ name: await routeto(to) })
    }
};

export const beforeEntering = async (to, from, next) => {
    try {
        if (store.state.sessionObj.userType === 2 && to && to.query && to.query.id) {
            const data = await checkData(to)
            if (data) next()
            else next({ name: await routeto(to) })
        }
        else if (store.state.sessionObj.userType === 1 || store.state.sessionObj.userType === 3) next()
        else next({ name: await routeto(to) })

    } catch (error) {
        next({ name: await routeto(to) })
    }
};
export default [
    {
        path: '/conditionTab',
        name: "Mortgage Loan Conditions",
        // component: () => import(/* webpackChunkName: "Los" */'@/common-components/urla/Urla.vue'),
        beforeEnter: beforeEnterForConditionTab,
        meta: {
            requiresAuth: true,
            title: "MortgageLoanConditions"
        }
    }, {
        path: '/los',
        name: "Los",
        component: () => import(/* webpackChunkName: "Los" */'@/common-components/urla/Urla.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Los"
        }
    },
    {
        path: '/loan-list',
        name: "LoanList",
        component: () => import(/* webpackChunkName: "LoanList" */'@/common-components/loan-list/LoanList.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "LoanList"
        }
    },
    {
        path: '/documents',
        name: "Documents",
        component: () => import(/* webpackChunkName: "Documents" */'@/common-components/documents/Documents.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Documents"
        }
    },
    {
        path: '/loan-process',
        name: "LoanProcess",
        component: () => import(/* webpackChunkName: "LoanProcess" */'@/components/processor/loan-process/LoanProcess.vue'),
        beforeEnter: beforeEntering,
        meta: {
            requiresAuth: true,
            title: "LoanProcess"
        }
    },
    {
        path: '/myAccount',
        name: "My Account",
        component: () => import(/* webpackChunkName: "My Account" */'@/common-components/my-account/MyAccount.vue'),
        beforeEnter: beforeEnter,
        // beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "My Account"
        }
    },
    {
        path: '/workflow',
        name: "WorkFlow",
        component: () => import(/* webpackChunkName: "WorkFlow" */'@/common-components/assign-task/ShowWorkFlow.vue'),
        beforeEnter: beforeEnter,
        // beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "WorkFlow"
        }
    },
    {
        path: '/notification',
        name: "Notification",
        component: () => import(/* webpackChunkName: "Notification" */'@/common-components/Notification.vue'),
        beforeEnter: beforeEnter,
        // beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Notification"
        }
    },
    {
        path: '/master-credentials',
        name: "Master Credentials",
        component: () => import(/* webpackChunkName: "MasterCredentials" */'@/common-components/master-credentials/MasterCredentials.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Master Credentials"
        }
    },
    // {
    //     path: '/lender',
    //     name: "Lender",
    //     component: () => import(/* webpackChunkName: "Lender" */'@/common-components/lender/Lender.vue'),
    //     beforeEnter: beforeEnter,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Lender"
    //     }
    // },
    {
        path: '/lender',
        name: "New Lender",
        component: () => import(/* webpackChunkName: "Lender" */'@/common-components/new-lender/Lender.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Lender"
        }
    },
    {
        path: '/mortgage',
        name: "Mortgage Clause",
        component: () => import(/* webpackChunkName: "Lender" */'@/common-components/new-lender/Mortgage.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "Lender"
        }
    },
    {
        path: '/manage-broker',
        name: "ManageBroker",
        component: () => import(/* webpackChunkName: "ManageBroker" */'@/common-components/manage-broker/ManageBroker.vue'),
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true,
            title: "ManageBroker"
        }
    },

]