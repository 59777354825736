import Address from '../common/address.model'
export class CompanyInfo {
    constructor() {
        this.address = new Address();
        this.email = null;
        this.cellPhone = null;
        this.workPhone = null;
        this.workPhoneExt = null;
        this.faxNumber = null;
        this.logo = null;
        this.name = null;
        this.tin = null;
      }
      
      public address: Address;
    
      public email: any;

      public cellPhone: any;

      public workPhone: any;
  
      public workPhoneExt: any;

      public faxNumber: any;

      public logo: any;

      public name: any;

      public tin: any;
    
}